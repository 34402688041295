import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { Link } from 'gatsby'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import mugshot from '../images/mugshot.jpg'

import './NavigationBar.scss'


// =============================================================================
const NavigationBar = ({ title, description, baseCopyrightYear, children }) => {
  // Copyright duration.
  const startYear = baseCopyrightYear
  const endYear = (new Date()).getFullYear()

  // Control the visibility of the content area.
  const [showContent, setShowContent] = useState(false)

  return (
    <div className="NavigationBar">
      <header>
        <img src={mugshot} alt="Me smiling." />
        <h1><Link to="/">{title}</Link></h1>
        <button id="navToggle" onClick={() => setShowContent(!showContent)}>
          <FontAwesomeIcon icon="bars" size="2x" />
        </button>
      </header>
      <div className={`content ${showContent ? 'show' : 'hide'}`}>
        <p className="subtitle">{description}</p>
        {children}
        <footer>
          Copyright &copy; {startYear}&ndash;{endYear} Kier Dugan.
        </footer>
      </div>
    </div>
  )
}

// -----------------------------------------------------------------------------
NavigationBar.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  baseCopyrightYear: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
}

// -----------------------------------------------------------------------------
export default NavigationBar
