import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'

import { TagCloud } from 'react-tagcloud'

import TagLink from './TagLink'

import './NavigationBarTagCloud.scss'


// =============================================================================
const tagRenderer = (tag, size, colour) => (
  <TagLink
    tagName={tag.value}
    key={tag.value}
    usageCount={tag.count}
    size={size}
    colour={colour}
    dark
  />
)


// =============================================================================
const NavigationBarTagCloud = ({ tags }) => {
  const data = _.map(tags, (count, tag) => ({ value: tag, count }));

  return (
    <section className="tagCloud">
      <TagCloud
        tags={data} minSize={100} maxSize={125}
        renderer={tagRenderer} shuffle={false}
        colorOptions={{ luminosity: 'dark', hue: 'yellow' }} />
    </section>
  )
}

// -----------------------------------------------------------------------------
NavigationBarTagCloud.propTypes = {
  tags: PropTypes.objectOf(PropTypes.number).isRequired,
}

// -----------------------------------------------------------------------------
export default NavigationBarTagCloud
