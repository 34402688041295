import _ from 'lodash'
import React from 'react'

import { StaticQuery, graphql } from 'gatsby'

import extractTags from '../lib/extractTags'

import NavigationBar from './NavigationBar'
import NavigationBarLinks from './NavigationBarLinks'
import NavigationBarSocialLink from './NavigationBarSocialLink'
import NavigationBarSocialLinks from './NavigationBarSocialLinks'
import NavigationBarTagCloud from './NavigationBarTagCloud'


// =============================================================================
const Navigation = () => (
  <StaticQuery
    query={graphql`
      query NavigationQuery {
        site {
          siteMetadata {
            title
            description
            baseCopyrightYear
            social {
              icon
              iconType
              handle
              url
            }
          }
        }
        allMdx(filter: { publish: { eq: true } }) {
          nodes {
            frontmatter {
              tags
            }
          }
        }
      }
    `}
    render={data => {
      const {
        title,
        description,
        social,
        baseCopyrightYear,
      } = data.site.siteMetadata

      const posts = data.allMdx.nodes

      return (
        <NavigationBar
          title={title}
          description={description}
          baseCopyrightYear={baseCopyrightYear}
        >
          <NavigationBarSocialLinks>
            {_.map(social, ({ icon, ...link }) => (
              <NavigationBarSocialLink
                key={link.url}
                iconName={icon}
                {...link}
              />
            ))}
          </NavigationBarSocialLinks>
          <NavigationBarLinks />
          <NavigationBarTagCloud tags={extractTags(posts)} />
        </NavigationBar>
      )
    }}
  />
)

// -----------------------------------------------------------------------------
export default Navigation
