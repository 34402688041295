import React from 'react'

import CookieConsent from 'react-cookie-consent'

import './CookieBanner.scss'


// =============================================================================
const CookieBanner = () => (
  <CookieConsent
    containerClasses="CookieBanner"
    contentClasses="content"
    disableStyles={true}
    location="none"
  >
    Cookies are used on this site to provide you with a better user experience.
  </CookieConsent>
)

// -----------------------------------------------------------------------------
export default CookieBanner
