import React from 'react'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import './NavigationBarSocialLink.scss'


// =============================================================================
const NavigationBarSocialLink = ({ iconName, iconType="fab", handle, url }) => {
  let icon = [iconType, iconName]

  if (!iconType) {
    icon = iconName
  }

  return (
    <li>
      <a href={url} title={handle} className={iconName}>
        <FontAwesomeIcon icon={icon} size="2x" />
      </a>
    </li>
  )
}

// -----------------------------------------------------------------------------
NavigationBarSocialLink.propTypes = {
  iconName: PropTypes.string.isRequired,
  iconType: PropTypes.string,
  handle: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
}

// -----------------------------------------------------------------------------
export default NavigationBarSocialLink
