import React from 'react'


// =============================================================================
const NavigationBarLinks = () => (
  <section className="nav">
    <a href="https://kierdugan.com">About Me</a>
  </section>
)

// -----------------------------------------------------------------------------
export default NavigationBarLinks
