import React from 'react'

import './NavigationBarSocialLinks.scss'


// =============================================================================
const NavigationBarSocialLinks = ({ children }) => (
  <section className="social">
    <ul>
      {children}
    </ul>
  </section>
)

// -----------------------------------------------------------------------------
export default NavigationBarSocialLinks
