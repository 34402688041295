import React from 'react'
import PropTypes from 'prop-types'

import { Link } from 'gatsby'

import educate from '@kdugan/educate'

import './TitleBlock.scss'


// =============================================================================
const TitleBlock = ({ title, small, path, children }) => {
  // Titles should always be made smart.
  title = educate(title)

  // If a path is given, this title-block is being used in some kind of list.
  if (path) {
    title = <Link to={path}>{title}</Link>
  }

  return (
    <header className={`TitleBlock${small ? ' small' : ''}`}>
      <h1>{title}</h1>
      {children && <div className="details">{children}</div>}
    </header>
  )
}

// -----------------------------------------------------------------------------
TitleBlock.propTypes = {
  title: PropTypes.string.isRequired,
  small: PropTypes.bool,
  path: PropTypes.string,
  children: PropTypes.node,
}

// -----------------------------------------------------------------------------
export default TitleBlock
