import { get, identity, isEmpty, isNil } from 'lodash'
import { flow, mapValues, pickBy, value } from 'lodash/fp'

import React from 'react'
import PropTypes from 'prop-types'

import classNames from 'classnames'

import { Link } from 'gatsby'

import './TagLink.scss'


// =============================================================================
const TagLink = ({ tagName, usageCount, size, colour, dark, small }) => {
  // If the usage count is given, it should appear in the tag text.
  const tagText =
    isNil(usageCount) ? tagName : <>{tagName}&nbsp;({usageCount})</>

  // If a size and/or colour is/are specified, the tag text must be wrapped to
  // apply the appropriate styles.
  const invoker = (transformer) =>
    (value, key) => get(transformer, key, identity)(value)

  const tagBodyStyle = flow(
    pickBy,
    mapValues(invoker({
      fontSize: value => `${value / 100}em`,
      color: identity,
    })),
    value
  )({ fontSize: size, color: colour })

  const tagBody =
    isEmpty(tagBodyStyle) ? tagText :
                              <span style={tagBodyStyle}>{tagText}</span>

  // Determine the appropriate classnames to apply
  const classes = classNames({
    'TagLink': true,
    'dark': dark === true,
    'small': small === true,
  })

  // Render the link
  return (
    <Link className={classes} to={`/tag/${tagName}`}>
      {tagBody}
    </Link>
  )
}

// -----------------------------------------------------------------------------
TagLink.propTypes = {
  tagName: PropTypes.string.isRequired,
  usageCount: PropTypes.number,
  size: PropTypes.number,
  colour: PropTypes.string,
  dark: PropTypes.bool,
  small: PropTypes.bool,
}

// -----------------------------------------------------------------------------
export default TagLink
