import React from 'react'
import PropTypes from 'prop-types'

import { Helmet } from 'react-helmet'

import { StaticQuery, graphql } from 'gatsby'

import educate from '@kdugan/educate'

import CookieBanner from '../components/CookieBanner'
import Navigation from '../components/Navigation'
import TitleBlock from '../components/TitleBlock'

import './Page.scss'


// =============================================================================
const Page = ({
  title,
  pageTitle,
  headerComponent,
  educateChildren = true,
  children,
}) => {
  // Allow having separate titles in the page's title block and the head block,
  // this allows a page to have a shorter or more descriptive title that will
  // be placed in the browser history.
  if (pageTitle === undefined) {
    pageTitle = title
  }

  // Pages may want to offer up a custom page header to provide relevant
  // meta-data (e.g., blog post pages).
  if (!headerComponent) {
    headerComponent = TitleBlock
  }

  // Apply smartypants to all textual children of elements.
  if (educateChildren) {
    function applySmartypants(node) {
      console.log(node)
      if (typeof node == 'string') {
        return educate(node)
      } else if (node.props.children) {
        return React.cloneElement(node, {
          children: React.Children.map(node.props.children, applySmartypants)
        })
      } else {
        return node
      }
    }

    children = React.Children.map(children, applySmartypants)
  }

  // Render the page.
  return (
    <>
      <StaticQuery
        query={graphql`
          query LayoutQuery {
            site {
              siteMetadata {
                title
                description
              }
            }
          }
        `}
        render={data => {
          const {
            title: siteTitle,
            description,
          } = data.site.siteMetadata

          const fullTitle = educate(
            `${pageTitle ? pageTitle + ' -- ' : ''}${siteTitle}`)

          return (
            <Helmet>
              <title>{fullTitle}</title>
              <meta name="description" content={description} />
            </Helmet>
          )
        }}
      />

      <Navigation />

      <main id="root">
        {title && headerComponent({ title })}
        <div className="Page-content">
          {children}
        </div>
      </main>

      <CookieBanner />
    </>
  )
}

// -----------------------------------------------------------------------------
Page.propTypes = {
  title: PropTypes.string,
  pageTitle: PropTypes.string,
  headerComponent: PropTypes.func,
  educateChildren: PropTypes.bool,
  children: PropTypes.node.isRequired,
}

// -----------------------------------------------------------------------------
export default Page
